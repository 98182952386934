
const COMMON_SETTINGS = {

    // these breakpoint values should correspond with 
    // the breakpoint values set in Sass
    breakpoints: {
        tabletStart: 768,
        tabletEnd: 1023,
        desktopStart: 1024
    },

    matchMediaBreakpoints: {
    	"large-mobile": "(min-width: 380px)",
		"large-mobile-max": "(max-width: 379px)",
		"phablet": "(min-width: 480px)",
		"phablet-max": "(max-width: 479px)",
		"small-tablet": "(min-width: 580px)",
		"tablet": "(min-width: 768px)",
		"tablet-max": "(max-width: 767px)",
		"large-tablet": "(min-width: 940px)",
		"large-tablet-max": "(max-width: 939px)",
		"desktop": "(min-width: 1024px)",
		"desktop-max": "(max-width: 1023px)",
		"wide": "(min-width: 1280px)",
		"wider": "(min-width: 1366px)",
		"widest": "(min-width: 1600px)"
    }
}

export default COMMON_SETTINGS;