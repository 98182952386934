
import * as browserDetection from '../utils/utils.browser-detection';

const 	contactSection = document.querySelector('.js-download-section'),
		scrollButtons = [...document.querySelectorAll('.js-scroll-to-download-section')];

function scrollToContact() {

	if ( browserDetection.isIE() ) {
		// ridiculous value to ensure scroll to bottom
		window.scrollTo(0, 50000);
	} 
	else {
		window.scrollTo({
			top: contactSection.getBoundingClientRect().top + window.scrollY,
			left: 0,
			behavior: 'smooth'
		});
	}
}

if ( scrollButtons.length ) {
	scrollButtons.forEach( button => {
		button.addEventListener('click', e => {
			e.preventDefault();
			scrollToContact();
		});
	});
}