
import 'intersection-observer';
import Promise from 'promise-polyfill';
window.Promise = window.Promise || Promise;
import anime from 'animejs';

const 	largeHeadings = [...document.querySelectorAll('.js-fade-in')];

let observer = new IntersectionObserver(observerCallback, {
	threshold: [.2]
});

function animateIn( heading ) {
	const animateHeading = heading.querySelector('.js-fade-in-animate');

	anime({
		targets: animateHeading,
		opacity: 1,
		translateY: 0,
		duration: 2000,
		easing: 'cubicBezier(0.165, 0.84, 0.44, 1)'
	});
}

function observerCallback( entries ) {
	entries.forEach( entry => {
		
		if ( entry.isIntersecting ) {
			observer.unobserve( entry.target );
			animateIn( entry.target );
		}
	});
}

function setInitialValues( heading ) {
	anime.set(heading, {
		translateY: 60,
		opacity: 0,
	});
}

if ( largeHeadings.length ) {

	largeHeadings.forEach( heading => {
		const animateHeading = heading.querySelector('.js-fade-in-animate');
		setInitialValues( animateHeading );
		observer.observe( heading );
	});
}