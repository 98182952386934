
export function isFirefox() {
	return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function isSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isIOSBrowser() {
    return navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);
}

export function isIE() {
	var msie = parseInt((/msie (\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1]);
	if (isNaN(msie)) {
		msie = parseInt((/trident\/.*; rv:(\d+)/.exec(navigator.userAgent.toLowerCase()) || [])[1]);
	}
    return !!msie;
}

export function isEdge() {
	return window.navigator.userAgent.indexOf("Edge") > -1;
}