
import COMMON_SETTINGS from '../settings/settings.common-settings';

/**
 * shortcut for window.matchMedia
 * @param {String} bp - the breakpoint to match
 * @return {Boolean}
 */
export function mmMatches(bp) {
    return window.matchMedia( COMMON_SETTINGS.matchMediaBreakpoints[bp] ).matches;
}