
import { mmMatches } from '../utils/utils.matchmedia';
import Rellax from 'rellax';

const 	parallax1 = [...document.querySelectorAll('.js-parallax-1')],
		parallaxNeg1 = [...document.querySelectorAll('.js-parallax-neg-1')],
		parallax2 = [...document.querySelectorAll('.js-parallax-2')],
		parallaxNeg2 = [...document.querySelectorAll('.js-parallax-neg-2')];

let		parallax1InstanceArray = [],
		parallaxNeg1InstanceArray = [],
		parallax2InstanceArray = [],
		parallaxNeg2InstanceArray = [],

		parallaxSet = false;

function setParallax() {
	
	parallaxSet = true;

	parallax1.forEach( (item, i) => {

		parallax1InstanceArray[i] = new Rellax(item, {
			speed: 1,
			center: true
		});
	});

	parallaxNeg1.forEach( (item, i) => {
		parallaxNeg1InstanceArray[i] = new Rellax(item, {
			speed: -1,
			center: true
		});
	});

	parallax2.forEach( (item, i) => {
		parallax2InstanceArray[i] = new Rellax(item, {
			speed: 2,
			center: true
		});
	});

	parallaxNeg2.forEach( (item, i) => {
		parallaxNeg2InstanceArray[i] = new Rellax(item, {
			speed: -2,
			center: true
		});
	});
}

function destroyParallax() {
	
	parallaxSet = false;
	
	if ( parallax1InstanceArray.length ) {
		parallax1InstanceArray.forEach( item => {
			item.destroy();
		});
	}

	if ( parallaxNeg1InstanceArray.length ) {
		parallaxNeg1InstanceArray.forEach( item => {
			item.destroy();
		});
	}

	if ( parallax2InstanceArray.length ) {
		parallax2InstanceArray.forEach( item => {
			item.destroy();
		});
	}

	if ( parallaxNeg2InstanceArray.length ) {
		parallaxNeg2InstanceArray.forEach( item => {
			item.destroy();
		});
	}
}

if ( mmMatches( 'desktop' ) ) {
	setParallax();
}

window.addEventListener( 'resize', e => {
	
	if ( mmMatches( 'desktop' ) && !parallaxSet ) {
		setParallax();
	} else if ( !mmMatches( 'desktop' ) && parallaxSet ) {
		destroyParallax();
	}
});

